import { Button } from 'antd'
import { TwitterShareButton } from 'react-share'
import { useTranslation } from '~/common/hooks/useTranslation'
import { toPng } from 'html-to-image'
import html2canvas from 'html2canvas'

export const SharedOnTwitter = (props) => {
  const { urlShareTwitter, title, okButtonStyle } = props
  const { t } = useTranslation()

  const captureAndDownloadScreenshot = async () => {
    try {
      await document.fonts.ready

      window.scrollTo(0, 0)

      let dataUrl

      // Attempt to capture screenshot with toPng
      try {
        dataUrl = await toPng(document.documentElement, {
          cacheBust: true,
          quality: 0,
          pixelRatio: 2,
          backgroundColor: null,
        })
      } catch (error) {
        console.warn(
          'html-to-image failed, falling back to html2canvas:',
          error,
        )
      }

      // If toPng fails, fall back to html2canvas
      if (!dataUrl) {
        const canvas = await html2canvas(document.documentElement, {
          useCORS: true,
          scale: 2,
          backgroundColor: null,
        })
        dataUrl = canvas.toDataURL('image/png')
      }

      // Create a download link and trigger the download
      const link = document.createElement('a')
      link.href = dataUrl
      link.download = 'screenshot.png'

      link.click()
    } catch (error) {
      console.error('Error occurred while downloading the image:', error)
    }
  }

  return (
    <div>
      {urlShareTwitter && (
        <TwitterShareButton
          url={urlShareTwitter}
          title={title}
          hashtags={['ReMonster']}
        >
          <Button
            type="primary"
            style={okButtonStyle}
            onClick={captureAndDownloadScreenshot}
          >
            {t('modal.open_success.share_x')}
          </Button>
        </TwitterShareButton>
      )}
    </div>
  )
}
